import React, { useEffect, useState, useCallback } from 'react';
import styles from './DefaultPage.module.scss';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';
import Refresh from '../Icons/Refresh';

const DefaultPage = ({ openSongPage }) => {
  const [songs, setSongs] = useState([]);
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const db = getFirestore();

  const fetchRandomSongs = useCallback(async () => {
    setIsRefreshing(true);
    setIsLoading(true);
    try {
      const randomValue = Math.random();
      const songsRef = collection(db, 'songs');

      let songQuery = query(
        songsRef,
        where('random', '>=', randomValue),
        orderBy('random'),
        limit(10)
      );

      let snapshot = await getDocs(songQuery);

      if (snapshot.size < 10) {
        const fallbackQuery = query(
          songsRef,
          where('random', '<', randomValue),
          orderBy('random'),
          limit(10 - snapshot.size)
        );
        const fallbackSnapshot = await getDocs(fallbackQuery);
        snapshot = {
          docs: [...snapshot.docs, ...fallbackSnapshot.docs],
        };
      }

      const songList = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const songData = { id: docSnap.id, ...docSnap.data() };

          try {
            // Fetch user data
            const userRef = doc(db, 'users', songData.userId);
            const userSnap = await getDoc(userRef);
            if (userSnap.exists()) {
              songData.user = userSnap.data();
            } else {
              console.warn(`No user found for ID: ${songData.userId}`);
            }
          } catch (userError) {
            console.error(
              `Error fetching user data for ID: ${songData.userId}`,
              userError
            );
          }

          return songData;
        })
      );

      setSongs(songList);
    } catch (err) {
      console.error('Error fetching random songs:', err);
      setError('Failed to load random songs. Please try again later.');
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  }, [db]);

  useEffect(() => {
    fetchRandomSongs();
  }, [fetchRandomSongs]);

  const fallbackAvatar = './Avatar.png'; // Path to your default avatar image

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.toolbar}>
        <div className={styles.toolbarLeft}>
          <div className={styles.toolbarTitle}>
            <span className={styles.toolbarTitleText}>🌈 Random songs</span>
            <span className={styles.toolbarSubtitle}>from our community</span>
          </div>
        </div>
        <div className={styles.toolbarRight} onClick={fetchRandomSongs}>
          <div
            className={`${styles.toolbarButton} ${isRefreshing ? styles.spinning : ''}`}
          >
            <Refresh />
          </div>
        </div>
      </div>
      {isLoading && <div className={styles.loadingBar}></div>}
      {isLoading ? (
        <div className={styles.loadingState}>
          <p>Loading songs...</p>
        </div>
      ) : (
        <div className={styles.songList}>
          {songs.map((song, index) => (
            <div
              key={song.id}
              className={styles.songChip}
              onClick={() => openSongPage(song.id)}
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <img
                src={song.thumbnailURL}
                alt={song.songName}
                className={styles.thumbnail}
              />
              <div className={styles.songDetails}>
                <span className={styles.songName}>{song.songName}</span>
                <span className={styles.artist}>{song.songArtist}</span>
                {song.user && (
                  <div className={styles.userDetails}>
                    <img
                      src={song.user.avatar || fallbackAvatar}
                      alt="avi"
                      className={styles.userThumbnail}
                    />
                    <span className={styles.userName}>
                      {song.user.displayName}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DefaultPage;
