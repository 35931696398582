import React, { createContext, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  const storeScrollPosition = () => {
    sessionStorage.setItem('scrollPosition', window.scrollY);
  };

  const restoreScrollPosition = () => {
    const storedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (storedScrollPosition) {
      window.scrollTo(0, parseInt(storedScrollPosition, 10));
    }
  };

  const handleUserPageNav = useCallback(
    (userId) => {
      storeScrollPosition();
      navigate(`/user/${userId}`);
    },
    [navigate]
  );

  const handleHomeNav = useCallback(() => {
    storeScrollPosition();
    navigate('/');
    restoreScrollPosition();
  }, [navigate]);

  const handleNotificationsNav = useCallback(
    (userId) => {
      storeScrollPosition();
      navigate(`/notifs/${userId}`);
    },
    [navigate]
  );

  const openSongPage = useCallback(
    (songId) => {
      storeScrollPosition();
      navigate(`/song/${songId}`);
    },
    [navigate]
  );

  return (
    <NavigationContext.Provider
      value={{
        handleUserPageNav,
        handleHomeNav,
        handleNotificationsNav,
        openSongPage,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
