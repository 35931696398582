import { useNavigate } from 'react-router-dom';
import { useNavigation } from '../../config/NavigationContext';

const NavigationFrame = ({ openSongPage }) => {
  useNavigate();
  useNavigation();

  return null;
};

export default NavigationFrame;
